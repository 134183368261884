import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MetricsMasterService } from "src/app/shared/services/metrics-master.service";

@Component({
  selector: "app-metric-dialog",
  templateUrl: "./metric-dialog.component.html",
  styleUrls: ["./metric-dialog.component.scss"],
})
export class MetricDialogComponent implements OnInit {
  currentMetric: any;
  linkedMetrics: any;
  tableColumns: any = ["#", "Name"];
  constructor(
    public dialogRef: MatDialogRef<MetricDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private metricMasterService: MetricsMasterService
  ) {}

  ngOnInit(): void {
    this.currentMetric = this.data.metricInfo;
    this.getLinkedMetrics();
  }

  getLinkedMetrics() {
    this.metricMasterService
      .getLinkedMetrics(this.currentMetric.id)
      .subscribe((resp: any) => {
        this.linkedMetrics = resp;
      });
  }
}
