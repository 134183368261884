import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MetricsMasterService } from "src/app/shared/services/metrics-master.service";

@Component({
  selector: "app-metric-creation-dialog",
  templateUrl: "./metric-creation-dialog.component.html",
  styleUrls: ["./metric-creation-dialog.component.scss"],
})
export class MetricCreationDialogComponent implements OnInit {
  metricTags: any;
  isEditable: boolean = false;
  bscQuadOptions: any = [];
  aggregationTypeOptions: any = [];
  functionOptions: any = [];
  unitOptions: any = [];
  metricForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    display_name: new FormControl(""),
    description: new FormControl("", [Validators.required]),
    is_okr: new FormControl(false),
    is_toe: new FormControl(false),
    is_balance_score: new FormControl(false),
    is_kpi_metric: new FormControl(false),
    is_sla_metric: new FormControl(false),
    is_cc_metric: new FormControl(false),
    is_kra_metric: new FormControl(false),
    is_kpa_metric: new FormControl(false),
    phase: new FormControl(""),
    function: new FormControl(""),
    aggregation_type: new FormControl(""),
    domain: new FormControl(""),
    metrics_unit: new FormControl(""),
    formula: new FormControl(""),
    target: new FormControl(""),
    mos_type_name: new FormControl(""),
    off_track_range_value: new FormControl(""),
    on_track_range_value: new FormControl(""),
    at_risk_range_value: new FormControl(""),
  });

  constructor(
    public dialogRef: MatDialogRef<MetricCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private toastrService: ToastrService,
    private metricsMasterService: MetricsMasterService
  ) {}

  ngOnInit() {
    this.getOptions();
    this.metricForm.get("name")?.valueChanges.subscribe((value) => {
      this.updateDisplayName(value);
    });
    if (this.data.metricInfo) {
      this.isEditable = true;
      this.data.metricInfo["phase"] += "";
      this.data.metricInfo["function"].id += "";
      this.data.metricInfo["aggregation_type"] += "";
      this.data.metricInfo["metrics_unit"] += "";
      this.metricForm.patchValue(this.data.metricInfo);
      this.metricForm.patchValue({
        function: this.data.metricInfo.function.id,
      });
      this.metricTags = this.data.metricInfo.tag_ids;
    }
  }

  updateDisplayName(value: string) {
    this.metricForm.get("display_name")?.setValue(value);
  }

  getBscQuadOptions() {
    this.metricsMasterService.getBscQuadList().subscribe((resp: any) => {
      this.bscQuadOptions = resp;
    });
  }

  getAggregationTypeOptions() {
    this.metricsMasterService.getAggregationList().subscribe((resp: any) => {
      this.aggregationTypeOptions = resp;
    });
  }

  getFunctionOptions() {
    this.metricsMasterService.getFunctionList().subscribe((resp: any) => {
      this.functionOptions = resp;
    });
  }

  getTypeOptions() {
    this.metricsMasterService.getUnitList().subscribe((resp: any) => {
      this.unitOptions = resp;
    });
  }

  getOptions() {
    this.getBscQuadOptions();
    this.getAggregationTypeOptions();
    this.getFunctionOptions();
    this.getTypeOptions();
  }

  getChildTags(data: any) {
    this.metricTags = data;
  }

  createMetric() {
    let dialogData = this.metricForm.value;
    dialogData["selectedTags"] = this.metricTags;
    this.dialogRef.close(dialogData);
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }
}
