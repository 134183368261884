import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MetricsMasterService {
  constructor(private http: HttpClient) {}

  getMetricsData(startValue: any, EndValue: any) {
    return this.http.get(
      `/metrics/metric-master/?start=${startValue}&end=${EndValue}`
    );
  }

  createMetric(payload: any) {
    return this.http.post("/metrics/metric-master/", payload);
  }

  editMetric(metricId: any, payload: any) {
    return this.http.patch(`metrics/metric-master/${metricId}/`, payload);
  }

  getBscQuadList() {
    return this.http.get("base_value/?type=metric_quadrant");
  }

  getAggregationList() {
    return this.http.get("base_value/?type=mos_formula");
  }

  getFunctionList() {
    return this.http.get("base_value/?type=metrics_function");
  }

  getUnitList() {
    return this.http.get("base_value/?type=mos_unit");
  }

  getTypesList() {
    return this.http.get("base_value/?type=metrics_type");
  }

  getCategoryList() {
    return this.http.get("base_value/?type=metrics_category");
  }

  getFilteredMetricsData(
    category: any,
    name: any,
    domain: any,
    type: any,
    func: any,
    quadrant: any,
    start: any,
    end: any
  ) {
    return this.http.get(
      `/metrics/metric-master?start=${start}&end=${end}&search=category:${category},name:${name},domain:${domain},type:${type},function:${func},phase:${quadrant}`
    );
  }

  getLinkedMetrics(metricId: any) {
    return this.http.get(
      `metrics/dependent-matric/?independent_metric=${metricId}`
    );
  }
}
