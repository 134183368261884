<app-modal-header-common title="Linked Metrics"> </app-modal-header-common>
<div class="plan-form">
  <h6>{{ currentMetric?.display_name }}</h6>
  <div class="custom-table fixed-table-header">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let header of tableColumns" scope="col">
            <span class="d-flex justify-content-center">{{ header }}</span>
          </th>
        </tr>
      </thead>
      <tbody class="cursor-pointer">
        <tr *ngFor="let item of linkedMetrics; let i = index">
          <td scope="row">
            <span class="d-flex justify-content-center fs-13">
              {{ i + 1 }}
            </span>
          </td>
          <td>
            <span class="d-flex justify-content-center fs-13">
              {{ item?.display_name }}
            </span>
          </td>
        </tr>
      </tbody>
      <tr *ngIf="!linkedMetrics?.length">
        <td colspan="6" class="noRecords text-center mt-2">
          <span class="mt-2">No records found </span>
        </td>
      </tr>
    </table>
  </div>
</div>
