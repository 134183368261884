<div class="row mb-3">
  <div
    class="col-sm-6"
    style="display: flex; align-items: center; justify-content: start"
  >
    <h5 class="m-0">Metrics Master</h5>
  </div>
  <div
    class="col-sm-6"
    style="display: flex; align-items: center; justify-content: end"
  >
    <button
      mat-flat-button
      color="primary"
      mat-button
      class="bg-primary text-white"
      aria-label="add"
      (click)="createMetric()"
    >
      <mat-icon>add</mat-icon> Metric
    </button>
  </div>
</div>
<div class="filter-container mb-2 mt-3">
  <div class="filter-icon">
    <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
  </div>
  <mat-form-field
    class="w-100 filter-dropdown"
    appearance="outline"
    style="font-size: 12px"
  >
    <mat-select
      [(ngModel)]="filterCategory"
      (ngModelChange)="search()"
      placeholder="Search Category.."
    >
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let cat of categoriesList" value="{{ cat.name }}">{{
        cat.display_name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <input
    type="text"
    placeholder="Search Name..."
    class="form-control text-input"
    [(ngModel)]="filterName"
    (ngModelChange)="search()"
    style="min-width: 13rem; border-radius: 8px; font-size: 12px; height: 40px"
  />
  <input
    type="text"
    placeholder="Search Domain..."
    class="form-control text-input"
    [(ngModel)]="filterDomain"
    (ngModelChange)="search()"
    style="min-width: 13rem; border-radius: 8px; font-size: 12px; height: 40px"
  />

  <mat-form-field
    class="w-100 filter-dropdown"
    appearance="outline"
    style="font-size: 12px"
  >
    <mat-select
      [(ngModel)]="filterType"
      (ngModelChange)="search()"
      placeholder="Search Type..."
    >
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let opt of typesList" value="{{ opt.name }}">{{
        opt.display_name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    class="w-100 filter-dropdown"
    appearance="outline"
    style="font-size: 12px"
  >
    <mat-select
      [(ngModel)]="filterFunction"
      (ngModelChange)="search()"
      placeholder="Search Function..."
    >
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let opt of functionList" value="{{ opt.id }}">{{
        opt.display_name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    class="w-100 filter-dropdown"
    appearance="outline"
    style="font-size: 12px"
  >
    <mat-select
      [(ngModel)]="filterQuadrant"
      (ngModelChange)="search()"
      placeholder="Search Quadrant..."
    >
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let opt of quadrantList" value="{{ opt.id }}">{{
        opt.display_name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div style="overflow-x: hidden">
  <div class="mt-4">
    <div class="flex-container-1">
      <app-loading
        style="width: 100%; min-height: 200px"
        *ngIf="!metricData"
      ></app-loading>

      <div class="col-md-12">
        <div class="custom-height">
          <div
            class="custom-table table-responsive"
            style="max-height: calc(100vh - 220px)"
          >
            <table class="table table-bordered">
              <thead style="position: sticky; z-index: 1">
                <tr>
                  <th
                    *ngFor="let field of tableColumns"
                    scope="col"
                    class="text-center"
                  >
                    {{ field }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let element of metricData?.records; let i = index"
                >
                  <tr>
                    <td
                      (click)="openDetails(element)"
                      class="metric-name text-center"
                    >
                      {{ element?.display_name }}
                    </td>
                    <td>{{ element?.metric_unit_name }}</td>
                    <td>{{ element?.formula }}</td>
                    <td>{{ element?.target }}</td>
                    <td>{{ element?.type }}</td>
                    <td>{{ element?.function?.display_name }}</td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="element?.is_okr"
                        style="pointer-events: none"
                      ></mat-checkbox>
                    </td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="element?.is_toe"
                        style="pointer-events: none"
                      ></mat-checkbox>
                    </td>
                    <td class="text-center">
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <mat-checkbox
                          [checked]="element?.is_balance_score"
                          style="pointer-events: none"
                        ></mat-checkbox>
                      </div>
                    </td>
                    <td class="text-center">
                      <button
                        class="small-icon-button mb-2"
                        mat-icon-button
                        (click)="showLinkedMetric(element)"
                        matTooltip="Linked metrics"
                        *ngIf="element?.is_linked_metric"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                      <span *ngIf="!element?.is_linked_metric"> - </span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
              <tr *ngIf="!metricData?.total_count">
                <td colspan="14" class="noRecords text-center mt-2">
                  <span class="mt-2"
                    >{{ staticText.common.no_records_found }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="metricData?.total_count > 0"
        >
          <div class="p-1">
            <div
              class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
            >
              <span class="p-1">Displaying</span>
              <select
                class="form-select form-select-sm table-sort-filter"
                aria-label="Default select example"
                [(ngModel)]="sortRecords"
                (ngModelChange)="getRecords()"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
              <span class="p-1">{{ metricData?.total_count }}</span>
              <span class="p-1">records</span>
            </div>
          </div>
          <div class="p-1">
            <ngb-pagination
              [pageSize]="sortRecords"
              [collectionSize]="metricData?.total_count"
              [maxSize]="2"
              [rotate]="true"
              [(page)]="CurrentPage"
              [boundaryLinks]="true"
              (pageChange)="getRecords()"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
