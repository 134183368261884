<app-modal-header-common
  title="{{ isEditable ? 'Metric Details' : 'Create Metric' }}"
>
</app-modal-header-common>
<div class="plan-form">
  <form [formGroup]="metricForm">
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Display Name</mat-label>
          <input matInput formControlName="display_name" />
        </mat-form-field>
      </div>
    </div>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <div class="my-1">
      <span>Category</span>
      <div class="d-flex gap-4">
        <mat-checkbox formControlName="is_okr">Is OKR</mat-checkbox>
        <mat-checkbox formControlName="is_toe"
          >Is Delivery Obligation</mat-checkbox
        >
        <mat-checkbox formControlName="is_balance_score"
          >Is Balanced Scorecard</mat-checkbox
        >
      </div>
    </div>
    <mat-form-field
      class="w-100"
      appearance="outline"
      *ngIf="metricForm.get('is_balance_score')?.value"
    >
      <mat-label>BSC Quadrant</mat-label>
      <mat-select formControlName="phase">
        <mat-option *ngFor="let opt of bscQuadOptions" value="{{ opt.id }}">
          {{ opt.display_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="my-1">
      <span>Type</span>
      <div class="d-flex gap-4">
        <mat-checkbox formControlName="is_kpi_metric">KPI</mat-checkbox>
        <mat-checkbox formControlName="is_sla_metric">SLA</mat-checkbox>
        <mat-checkbox formControlName="is_cc_metric">CC</mat-checkbox>
        <mat-checkbox formControlName="is_kra_metric">KRA</mat-checkbox>
        <mat-checkbox formControlName="is_kpa_metric">KPA</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Function</mat-label>
          <mat-select formControlName="function">
            <mat-option
              *ngFor="let opt of functionOptions"
              value="{{ opt.id }}"
            >
              {{ opt.display_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Aggregation Type</mat-label>
          <mat-select formControlName="aggregation_type">
            <mat-option
              *ngFor="let opt of aggregationTypeOptions"
              value="{{ opt.id }}"
            >
              {{ opt.display_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Unit</mat-label>
          <mat-select formControlName="metrics_unit">
            <mat-option *ngFor="let opt of unitOptions" value="{{ opt.id }}">
              {{ opt.display_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Domain</mat-label>
          <input matInput formControlName="domain" />
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Formula</mat-label>
          <input matInput formControlName="formula" />
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Target Value</mat-label>
          <input type="number" step="1" matInput formControlName="target" />
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>HTB/LTB</mat-label>
          <mat-select formControlName="mos_type_name">
            <mat-option value="htb"> HTB </mat-option>
            <mat-option value="ltb"> LTB </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Off Track</mat-label>
          <input matInput formControlName="off_track_range_value" />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>On Track</mat-label>
          <input matInput formControlName="on_track_range_value" />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>On Risk</mat-label>
          <input matInput formControlName="at_risk_range_value" />
        </mat-form-field>
      </div>
    </div>
    <app-tag
      [noIds]="true"
      [tagsIds]="metricTags"
      (childTags)="getChildTags($event)"
    ></app-tag>
    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="createMetric()"
        [disabled]="metricForm.invalid"
      >
        {{ isEditable ? "Save" : "Create" }}
      </button>
    </div>
  </form>
</div>
