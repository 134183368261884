import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { IndexComponent } from "./index/index.component";
import { MatButtonModule } from "@angular/material/button";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgxPermissionsModule } from "ngx-permissions";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AvatarModule } from "ngx-avatar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";

import { MatCheckboxModule } from "@angular/material/checkbox";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { AutosizeModule } from "ngx-autosize";
import { MetricsMasterRoutingModule } from "./metrics-master-routing.module";
import { ListComponent } from "./list/list.component";
import { MetricCreationDialogComponent } from "./metric-creation-dialog/metric-creation-dialog.component";
import { MetricDialogComponent } from './metric-dialog/metric-dialog.component';

@NgModule({
  declarations: [IndexComponent, ListComponent, MetricCreationDialogComponent, MetricDialogComponent],
  imports: [
    CommonModule,
    SharedModule,
    MetricsMasterRoutingModule,
    NgxPermissionsModule,
    MatButtonModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    NgCircleProgressModule.forRoot({}),
    NgxPermissionsModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatStepperModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCardModule,
    MatChipsModule,
    NgxDatatableModule,
    AvatarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatTabsModule,
    MatExpansionModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatListModule,
    MatCheckboxModule,
    Ng2SearchPipeModule,
    AutosizeModule,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class MetrcisMasterModule {}
