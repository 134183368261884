import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { FilterService } from "src/app/shared/services/filter.service";
import { MatDialog } from "@angular/material/dialog";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
import { MetricsMasterService } from "src/app/shared/services/metrics-master.service";
import { MetricCreationDialogComponent } from "../metric-creation-dialog/metric-creation-dialog.component";
import { TagsService } from "src/app/shared/services/tag.service";
import { ToastrService } from "ngx-toastr";
import { MetricDialogComponent } from "../metric-dialog/metric-dialog.component";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  metricData: any;
  categories: any = [];
  functionList: any = [];
  quadrantList: any = [];
  typesList: any = [];
  categoriesList: any = [];
  tableColumns: any = [
    "Metric",
    "Unit",
    "Formula",
    "Target Value",
    "Type",
    "Function",
    "OKR",
    "Delivery Obligations",
    "Balanced Scorecard",
    "Linked Metrics",
  ];
  filterCategory: any = null;
  filterName: any = null;
  filterDomain: any = null;
  filterType: any = null;
  filterFunction: any = null;
  filterQuadrant: any = null;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  firstTimeLoad: boolean = true;

  constructor(
    private metricsMasterService: MetricsMasterService,
    private tagService: TagsService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private breadCrumService: BreadcrumbService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.setBreadCrumbs();
    this.getMetricData();
    this.getFilterOptions();
  }

  getFunctionList() {
    this.metricsMasterService.getFunctionList().subscribe((resp: any) => {
      this.functionList = resp;
    });
  }

  getQuadrantList() {
    this.metricsMasterService.getBscQuadList().subscribe((resp: any) => {
      this.quadrantList = resp;
    });
  }

  getTypesList() {
    this.metricsMasterService.getTypesList().subscribe((resp: any) => {
      this.typesList = resp;
    });
  }
  getCategoryList() {
    this.metricsMasterService.getCategoryList().subscribe((resp: any) => {
      this.categoriesList = resp;
    });
  }

  getFilterOptions() {
    this.getFunctionList();
    this.getQuadrantList();
    this.getTypesList();
    this.getCategoryList();
  }

  setBreadCrumbs() {
    let breadCrumb = [
      { label: "IPDB", url: "/prism/ipdb" },
      { label: "Metrics Master", url: "/metrics-master" },
    ];
    this.breadCrumService.setBreadcrumb(breadCrumb);
  }

  getMetricData() {
    this.metricsMasterService
      .getFilteredMetricsData(
        this.filterCategory,
        this.filterName,
        this.filterDomain,
        this.filterType,
        this.filterFunction,
        this.filterQuadrant,
        this.startValue,
        this.EndValue
      )
      .subscribe((resp) => {
        this.metricData = resp;
      });
  }

  async addTags(id: any, selectedTags: any) {
    if (selectedTags?.length > 0) {
      await selectedTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "master_metric",
          tag_id: element.id ? element.id : element,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  createMetric() {
    const dialog = this.dialog.open(MetricCreationDialogComponent, {
      width: "800px",
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.metricsMasterService
          .createMetric(data)
          .subscribe((response: any) => {
            this.spinner.hide();
            if (response) {
              this.addTags(response.id, data.selectedTags);
              this.toastrService.success("Metric created successfully...");
              this.getMetricData();
            }
          });
      }
    });
  }

  search() {
    this.metricsMasterService
      .getFilteredMetricsData(
        this.filterCategory,
        this.filterName,
        this.filterDomain,
        this.filterType,
        this.filterFunction,
        this.filterQuadrant,
        this.startValue,
        this.EndValue
      )
      .subscribe((resp) => {
        this.metricData = resp;
      });
  }

  openDetails(element: any) {
    const dialog = this.dialog.open(MetricCreationDialogComponent, {
      width: "800px",
      data: { metricInfo: element },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.metricsMasterService
          .editMetric(element.id, data)
          .subscribe((response: any) => {
            this.spinner.hide();
            if (response) {
              this.addTags(response.id, data.selectedTags);
              this.toastrService.success("Metric created successfully...");
              this.getMetricData();
            }
          });
      }
    });
  }

  getRecords(isFromAttrition?: boolean) {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if (!this.firstTimeLoad) {
      this.getMetricData();
    }
    this.firstTimeLoad = false;
  }

  showLinkedMetric(item: any) {
    const dialog = this.dialog.open(MetricDialogComponent, {
      width: "500px",
      data: {
        metricInfo: item,
      },
    });
  }
}
